import axios from 'redaxios';
import { throwIfGatewayTimeout } from 'api/proxy';

const action = 'ConnectDB';

export const fetchLocal = (lang: any) =>
  axios
    .post('/api/router.php', {
      action,
      data: [{ id: lang }],
      method: 'Locale',
      type: 'rpc',
    })
    .then((res) => res?.data?.result)
    .catch(throwIfGatewayTimeout);

export const loadLocale = (lang: any) =>
  axios
    .post('/api/router.php', {
      action,
      data: [{ lang }],
      method: 'Locale',
      type: 'rpc',
    })
    .then((res) => res?.data?.result)
    .catch(throwIfGatewayTimeout);

export const updateLocale = (lang: string) =>
  axios
    .post('/api/router.php', {
      action,
      data: [{ id: lang }],
      method: 'updateLocale',
      type: 'rpc',
    })
    .then((res) => res?.data?.result)
    .catch(throwIfGatewayTimeout);
